<script lang="ts" setup>
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'

const { getUser } = useUserState()
const user = getUser()
</script>

<template>
  <div>
    <div>
      <layout-admin-sidebar :user />
    </div>

    <div class="main-content">
      <slot />
    </div>

    <portal-target id="app-bottom" />
  </div>
</template>

<style lang="scss" scoped>
@supports (not (selector(:has(*)))) {
  .main-content {
    margin-left: 250px;

    .container-fluid {
      padding-left: 2.25rem !important;
      padding-right: 2.25rem !important;
    }
  }
}
</style>
