<script lang="ts" setup>
import type User from '~/src/Domain/Identity/User'
import useAdminNavigation from '~/src/UserInterface/App/composables/Admin/useAdminNavigation'

defineProps<{
  user: User
}>()

const { adminOrganisationsListLocalePath, adminEngagementsListLocalePath } = useAdminNavigation()
</script>

<template>
  <ui-navbar
    id="sidebar"
    class="navbar-vertical fixed-start navbar-expand-md navbar-light"
  >
    <ui-navbar-brand>
      <ui-img
        src="/img/logo-inverse.svg"
        class="navbar-brand-img"
      />
    </ui-navbar-brand>

    <ui-collapse is-nav>
      <ui-navbar-nav>
        <ui-nav-item :to="adminOrganisationsListLocalePath">
          <ui-icon
            icon="building"
            class="me-3"
          />
          {{ $t('components.layout.admin.sidebar.nav_organisations') }}
        </ui-nav-item>
        <ui-nav-item :to="adminEngagementsListLocalePath">
          <ui-icon
            icon="envelope"
            class="me-3"
          />
          {{ $t('components.layout.admin.sidebar.nav_engagements') }}
        </ui-nav-item>
      </ui-navbar-nav>
    </ui-collapse>

    <ui-navbar-user class="d-none d-md-flex mt-4">
      <notifications-button />
      <identity-avatar-dropdown :user />
      <intl-language-select-popover dropup>
        <template #button-text>
          <ui-icon
            icon="globe"
            class="fs-2"
          />
        </template>
      </intl-language-select-popover>
    </ui-navbar-user>
  </ui-navbar>
</template>
